import { graphql } from 'gatsby';
import * as React from 'react';
import styled from '@emotion/styled';

import { ContentBlock, StageBlock } from '../components/Blocks';
import Layout from '../components/layout';
import GatsbyImage from 'gatsby-image';

export default ({ data }: any) => {
  const gallery = data.allContentfulFotogalerie.edges[0].node;

  return (
    <Layout>
      <ContentBlock>
        <Data>
          <Title>{gallery.title}</Title>
          <Subtitle>{gallery.photographer}</Subtitle>
        </Data>
      </ContentBlock>

      <ContentBlock>
        {gallery.photos.map((photo: any, index: number) => (
          <GalleryImage
            key={index}
            style={{
              maxWidth:
                photo.fluid.aspectRatio < 1
                  ? '50%'
                  : photo.file.details.image.width,
            }}
            fluid={photo.fluid}
          />
        ))}
      </ContentBlock>
    </Layout>
  );
};

export const query = graphql`
  query($url: String!) {
    allContentfulFotogalerie(filter: { url: { eq: $url } }) {
      edges {
        node {
          title
          photographer
          photos {
            title
            file {
              details {
                image {
                  width
                }
              }
            }
            fluid(maxWidth: 1234) {
              aspectRatio
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;

const Data = styled.div`
  @media (max-width: 800px) {
    padding: 5rem 2rem 0 2rem;
  }
`;

const Title = styled.h2`
  position: relative;
  display: inline-block;
  font-size: 3rem;
  font-weight: 200;
  margin-bottom: 1rem;

  ::after {
    content: '';
    position: absolute;
    bottom: 0px;
    z-index: -1;
    left: 0;
    right: 0;
    height: 20px;
    background: rgba(210, 254, 250, 0.78);
  }
`;

const Subtitle = styled.h3`
  font-size: 1rem;
  font-weight: 800;
`;

const GalleryImage = styled(GatsbyImage)`
  margin: 0 auto 1.666rem;
`;
